var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-body",
    [
      _vm._t("default", function() {
        return [_vm._v("Loading")]
      }),
      _vm._l(_vm.dots, function(dot, index) {
        return _c("span", { key: index }, [_vm._v(".")])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }